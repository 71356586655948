import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import * as $ from 'jquery';
import { BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { Iframe } from 'src/app/iframe/shared/iframe.interface';
import { IframeService } from 'src/app/iframe/shared/iframe.service';
import { ProdutoOfertaTagsService } from 'src/app/produtos/produto-oferta-tags/shared/produto-oferta-tags.service';
import { FidelidadeService } from 'src/app/shared/services/fidelidade.service';
import { TermosUsoService } from 'src/app/shared/services/termos-uso.service';
import { ViewService } from 'src/app/shared/services/view.service';
import { DivulgacaoComponent } from 'src/app/sorteios/modal/divulgacao.component';
import { TipoEntregaService } from 'src/app/tipo-entregas/shared/tipo-entrega.service';
import { environment } from 'src/environments/environment';
import { ClienteService } from '../../clientes/shared/cliente.service';
import { Filial } from '../../filiais/shared/filiais';
import { FilialService } from '../../filiais/shared/filial.service';
import { InstitucionalService } from '../../institucional/shared/institucional.service';
import { Pagina } from '../../institucional/shared/paginas';
import { LoginModalComponent } from '../../login-modal/login-modal.component';
import { ProdutoBuscaService } from '../../produtos/produto-busca/shared/produto-busca.service';
import { Departamento } from '../../produtos/produto-departamento/shared/departamentos';
import { ReceitaService } from '../../receitas/shared/receita.service';
import { Receita } from '../../receitas/shared/receitas';
import { Colecao } from '../../shared/interfaces/colecoes';
import { Favorito } from '../../shared/interfaces/favorito';
import { ColecaoService } from '../../shared/services/colecao.service';
import { CombosService } from '../../shared/services/combos.service';
import { DepartamentoService } from '../../shared/services/departamento.service';
import { BannerService } from '../banners/shared/banner.service';
import { CarrinhoAnonimoService } from '../carrinho/shared/carrinho-anonimo.service';
import { CarrinhoService } from '../carrinho/shared/carrinho.service';
import { TermosUsoComponent } from '../termos-uso/termos-uso.component';
import { CriarContaModalComponent } from './../../criar-conta-modal/criar-conta-modal.component';
import { MinhaContaCashbackModalExtratoComponent } from './../../minha-conta/minha-conta-cashback/minha-conta-cashback-modal-extrato/minha-conta-cashback-modal-extrato.component';
import { MinhaContaCashbackService } from './../../minha-conta/minha-conta-cashback/shared/minha-conta-cashback.service';
import { UsuarioService } from './../../televendas/shared/usuario.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [CookieService, ProdutoOfertaTagsService, IframeService],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  public email = '';
  public password = '';
  public q = '';
  public isSearching: boolean = false;
  public tituloSite: string;
  public em_processo: boolean = false;
  public carrinho: any;
  public departamentos: Departamento[] = [];
  public favoritos: Favorito[] = [];
  public colecoes: Colecao[] = [];
  public receitas: Receita[] = [];
  public combos: any[] = [];
  public filial: Filial = <Filial>{};
  public placeholder: string;
  public televendas: boolean = false;
  public haOfertasExclusivas: boolean = false;
  public visibilidadeCd: boolean = false;
  public paginas: Pagina[] = [];
  public tagOfertaExclusiva: { descricao: string; slug: string } = {
    descricao: '',
    slug: '',
  };
  public iframe: Iframe;

  public dropdownOpened: string;

  public get mostrarLogin() {
    return !this.clienteLogado && !this.televendas;
  }

  public get mostrarMenuCliente() {
    return this.clienteLogado && !this.televendas;
  }

  public get mostrarTelevendas() {
    return !this.clienteLogado && this.televendas;
  }

  public get mostrarMenuClienteTelevendas() {
    return this.clienteLogado && this.televendas;
  }

  public get clienteLogado() {
    return this.clienteService.logged();
  }

  public get totalItensCarrinho() {
    return this.carrinhoService.total_itens;
  }

  private atualizaDepartamentos$: Subscription;
  private alterarLoja$: Subscription;
  private afterLogin$: Subscription;
  private afterLogout$: Subscription;
  private atualizaFavoritos$: Subscription;
  private afterAddCarrinho$: Subscription;
  private visibilidadeCd$: Subscription;

  public haCampanhaFidelidade: boolean = false;
  private subscribers: any = [];
  public saldo: number;
  public saldoSubscription: Subscription;
  public mostrarSaldoCashback: boolean = false;

  public get firstName() {
    let firstName = this.clienteService.nome;

    if (firstName && firstName.indexOf(' ') > -1) {
      firstName = firstName.split(' ')[0];
    }

    return firstName;
  }

  get nomeSite() {
    return this.cdService.cdSelecionado.nome_site;
  }

  constructor(
    private localStorageService: LocalStorageService,
    private clienteService: ClienteService,
    private carrinhoService: CarrinhoService,
    private bannerService: BannerService,
    private buscaService: ProdutoBuscaService,
    private departamentoService: DepartamentoService,
    private colecaoService: ColecaoService,
    private receitaService: ReceitaService,
    private filialService: FilialService,
    private router: Router,
    private combosService: CombosService,
    private usuarioService: UsuarioService,
    private modalService: BsModalService,
    private cdService: CentroDistribuicaoService,
    private produtoOfertaTagService: ProdutoOfertaTagsService,
    private location: Location,
    private fidelidadeService: FidelidadeService,
    public viewService: ViewService,
    private paginaService: InstitucionalService,
    private termosUsoService: TermosUsoService,
    private tipoEntregaService: TipoEntregaService,
    private cashbackService: MinhaContaCashbackService,
    private iframeService: IframeService,
    private carrinhoAnonimoService: CarrinhoAnonimoService,
  ) {
    this.setPlaceHolder();
    this.televendas = !!this.usuarioService.getUser();
  }

  public ngAfterViewInit() {
    this.router.events.subscribe(() => {
      this.fecharMenu();
    });
    this.clienteService.buscarSessaoCliente();
    this.carrinhoAnonimoService.verificarCarrinhoAnonimo();
  }

  public openLoginModal() {
    this.modalService.show(LoginModalComponent, {
      keyboard: true,
      class: 'custom-modal w280',
      ignoreBackdropClick: false,
    });
  }

  public openCreateAccountModal() {
    this.modalService.show(CriarContaModalComponent, {
      keyboard: true,
      class: 'custom-modal w280',
      ignoreBackdropClick: false,
    });
  }

  public atualizarBuscas = () => {
    this.q = this.buscaService.termo;
  };

  public limparMenuCliente = () => {
    $('.nav-tabs').find('a:first').click();
  };

  public eventCompraFinalizada = () => {
    this.carrinhoService.limparInfoCarrinho();
    this.em_processo = false;
  };

  private buscaOfertasExclusivas() {
    if (this.clienteLogado) {
      const produtoOfertaTagService$ = this.produtoOfertaTagService
        .ofertaExclusiva(this.cdService.cdSelecionado.id)
        .subscribe((req) => {
          this.haOfertasExclusivas = req.data.length > 0;
          produtoOfertaTagService$.unsubscribe();
        });
    }
  }

  private selecionaPrimeiroItemTabs() {
    $('.nav-tabs').find('a:first').click();
  }

  public atualizarCombos = () => {
    this.combos = this.combosService.combos;
  };

  public atualizaTelevendas(e) {
    this.televendas = e.detail;
  }

  private _buscaDepartamentos() {
    this.departamentoService.listagem(this.cdService.id);
  }

  private alterarLoja() {
    this.alterarLoja$ = this.cdService.selecionarLoja$.subscribe((info) => {
      if (info.lojaAlterada) {
        this.buscaOfertasExclusivas();
        this.recarregarBanners();
        this._buscaDepartamentos();
        this.verificarDisponibilidadeTipoEntrega();
        if (this.clienteLogado) {
          this.verificaIframe(false);
        }
      }
    });
  }

  private recarregarBanners() {
    this.bannerService.clearSessionStorageBanners();
    this.bannerService.home(this.cdService.id);
  }

  private autalizarDepartamentos() {
    this.atualizaDepartamentos$ = this.departamentoService.atualizaDepartamentos$.subscribe(
      (departamentos: any) => {
        this.departamentos = departamentos;
      }
    );
  }

  formataFavoritos(favoritos) {
    return favoritos.map((item) => {
      const dp = this.departamentoService.findDepartamentoById(
        item.departamento_id
      );
      return {
        descricao: dp.descricao,
        quantidade: item.quantidade,
        link: dp.link,
        imagem: dp.imagem,
      };
    });
  }

  private buscaFavoritos() {
    this.atualizaFavoritos$ = this.departamentoService.atualizaFavoritos$.subscribe(
      (favoritos: any) => {
        this.favoritos = this.formataFavoritos(favoritos);
      }
    );
  }

  private buscarCarrinho() {
    if (this.clienteLogado) {
      const buscarCarrinho$ = this.carrinhoService
        .buscarCarrinho(this.cdService.id)
        .subscribe(() => {
          buscarCarrinho$.unsubscribe();
        });
    }
  }

  private afterLogin() {
    this.afterLogin$ = this.clienteService.afterLogin$.subscribe(() => {
      this.departamentoService.buscaFavoritos(this.cdService.id);
      this.buscaOfertasExclusivas();
      this.exibirFidelidade();
      this.verificarAceiteTermosUso();
      this.verificarDisponibilidadeTipoEntrega();
      if (!this.iframeService.getIframeStorage()) {
        this.verificaIframe(true);
      }
    });
  }

  private verificaIframe(redireciona: boolean) {
    this.iframeService.getUrlIframe(this.cdService.id)
      .subscribe((result: any) => {
        if (result && result.data) {
          this.setLocalStorageIframe(result.data);
          this.iframe = (result.data.url !== undefined) ? result.data : false;
          if (redireciona) {
            this.redirecionarIframe();
          }
        }
      });
  }

  private setLocalStorageIframe(iframeData: Iframe) {
    this.iframeService.setIframeStorage(iframeData);
  }

  private redirecionarIframe() {
    if (this.iframe && this.iframe.abertura_automatica)
      this.router.navigate(['/integracao']);
  }

  private afterLogout() {
    this.afterLogout$ = this.clienteService.afterLogout$.subscribe(() => {
      this.carrinhoService.limparInfoCarrinho();
      this.departamentoService.limparFavoritos();
      if (this.saldoSubscription) {
        this.mostrarSaldoCashback = false;
        this.saldoSubscription.unsubscribe();
        this.saldo = undefined;
      }
      this.clienteService.buscarSessaoCliente();
      if (!this.carrinhoAnonimoService.permitirCarrinhoAnonimo)
        this.carrinhoAnonimoService.setCarrinhoAnonimoVerificado(true);
    });
  }

  public ngOnInit() {
    this.sincronizarCarrinhoAnonimoSubject();
    this._buscaDepartamentos();
    this.alterarLoja();
    this.afterLogin();
    this.afterLogout();
    this.autalizarDepartamentos();
    this.buscaFavoritos();
    this.buscarCarrinho();
    this.buscaOfertasExclusivas();
    this.validaVisibilidadeCd();
    this.validaParametrosInApp();
    this.setTagOfertaExclusiva();
    this.verificarDisponibilidadeTipoEntrega();
    if (this.clienteLogado) {
      this.verificaIframe(false);
    }

    window.addEventListener('atualizarTelevendas', (e) =>
      this.atualizaTelevendas(e)
    );

    let interval = setInterval(() => {
      this.filial = this.filialService.detail;
      if (this.filial && this.filial.id) {
        clearInterval(interval);
      }
    }, 300);

    this.colecaoService.home().then(() => {
      this.colecoes = this.colecaoService.colecoes;
    });

    this.receitaService.index().then(() => {
      this.receitas = this.receitaService.receitas;
    });

    this.bannerService.home(this.cdService.id);

    window.addEventListener('atualizarBuscas', this.atualizarBuscas);
    window.addEventListener('limparMenuCliente', this.limparMenuCliente);
    window.addEventListener('compraFinalizada', this.eventCompraFinalizada);
    window.addEventListener('atualizarCombos', this.atualizarCombos);
    this.fidelidadeService.buscarConfiguracao().then(() => {
      this.exibirFidelidade();
      this.buscaFidelidadeToken();
    });
  }

  private buscarSaldoCashback() {
    this.cashbackService.getNeedUpdateValue().subscribe((needUpdate) => {
      if (needUpdate) {
        this.carregarSaldoCashback();
      }
    });
    this.carregarSaldoCashback();
  }

  public ngOnDestroy() {
    this.atualizaDepartamentos$.unsubscribe();
    this.atualizaFavoritos$.unsubscribe();
    this.alterarLoja$.unsubscribe();
    this.afterLogin$.unsubscribe();
    this.afterLogout$.unsubscribe();
    this.afterAddCarrinho$.unsubscribe();
    this.visibilidadeCd$.unsubscribe();

    window.removeEventListener('atualizarTelevendas', (e) =>
      this.atualizaTelevendas(e)
    );
    window.removeEventListener('atualizarBuscas', this.atualizarBuscas);
    window.removeEventListener('limparMenuCliente', this.limparMenuCliente);
    window.removeEventListener('compraFinalizada', this.eventCompraFinalizada);
    window.removeEventListener('atualizarCombos', this.atualizarCombos);

    this.subscribers.forEach((subscriber) => {
      subscriber.unsubscribe();
    });
    if (this.saldoSubscription) {
      this.saldoSubscription.unsubscribe();
      this.saldo = undefined;
    }
  }

  public atendimento() {
    this.filialService.view(environment.filialId).then((data) => {
      if (this.filial.widget_chat !== '') {
        window.open(
          this.filial.widget_chat,
          'chat',
          'width=400px, height=550px, top=200px, left=300px'
        );
      }
    });
  }

  public logout() {
    this.mostrarSaldoCashback = false;
    if (this.saldoSubscription) {
      this.saldoSubscription.unsubscribe();
    }
    this.iframeService.removeIframeStorage();
    this.saldo = undefined;
    this.clienteService.logout().then(() => {
      this.router.navigate(['/']);
    });
    this.fecharMenu();
  }

  public realizarBusca() {
    if (this.q !== '') {
      this.router.navigate(['/buscas'], {
        queryParams: { q: this.q.replace(/ /g, '+') },
      });
      setTimeout(() => {
        if ($(window).width() < 992) {
          $('#q:visible').blur();
        }
      }, 100);
    }
  }

  public fecharMenu() {
    // TODO: Remover jQuery
    $('body').removeClass('sidemenu-open');
  }

  public haOfertas() {
    return (
      this.departamentos.filter(
        (departamento: Departamento) => departamento.total_ofertas > 0
      ).length > 0
    );
  }

  public haFavoritos() {
    return this.favoritos.length > 0;
  }

  public haColecoes() {
    return this.colecoes.length > 0;
  }

  public haReceitas() {
    return this.receitas.length > 0;
  }

  public haCombos() {
    return this.combos.length > 0;
  }

  public toggleMenu() {
    $('body').toggleClass('sidemenu-open');
  }

  public toggleSearch(event) {
    this.q = '';
    this.isSearching = !this.isSearching;

    if (this.isSearching) {
      event.preventDefault();
      event.stopPropagation();

      // Fix for iOS input focus bug
      const fakeInput: any = document.createElement('input');
      fakeInput.setAttribute('type', 'text');
      fakeInput.setAttribute('readonly', true);
      fakeInput.style.position = 'absolute';
      fakeInput.style.opacity = 0;
      fakeInput.style.height = 0;
      fakeInput.style.fontSize = '16px';
      document.body.prepend(fakeInput);
      fakeInput.focus();
      setTimeout(() => {
        $('#q').focus();
        fakeInput.remove();
      }, 500);
    }
  }

  private setPlaceHolder() {
    this.placeholder = this.viewService.getSearchPlaceholder();
  }

  public sairTelevendas() {
    this.localStorageService.clearAll();
    window.close();
    this.router.navigate(['/']);
    window.location.reload();
  }

  public openDropdownMenu(name) {
    this.dropdownOpened = this.dropdownOpened !== name ? name : '';
  }

  public dropDownOpenedClass(name) {
    return this.isDropdownOpened(name) ? 'open' : '';
  }

  public isDropdownOpened(name) {
    return this.dropdownOpened === name;
  }

  public abrirSelecaoLoja() {
    this.cdService.abrirSelecionarLoja();
    this.fecharMenu();
  }

  private validaVisibilidadeCd() {
    this.visibilidadeCd$ = this.cdService.visibilidadeCd$.subscribe(
      (visivel) => {
        this.visibilidadeCd = visivel;
      }
    );
  }

  public allButtonsMenuCliente() {
    return (
      !!this.filial.widget_chat &&
      this.haOfertasExclusivas &&
      this.visibilidadeCd
    );
  }

  public layoutMenuTelevendas() {
    let classe = 'nav-bar__cliente-menu-televendas';
    if (!this.visibilidadeCd) {
      classe = 'pull-right';
    }
    return classe;
  }

  private exibirFidelidade() {
    this.haCampanhaFidelidade = false;
    if (this.fidelidadeService.vorticeParams()['ativo']) {
      const getCampaigns$ = this.fidelidadeService
        .getCampaigns()
        .subscribe((res: any) => {
          this.haCampanhaFidelidade = this.fidelidadeService.haCampanhaFidelidade;

          const modalsCount = this.modalService.getModalsCount();
          const currentRoute = this.location.path();
          if (
            modalsCount === 0 &&
            !currentRoute.includes('cadastro') &&
            this.carrinhoAnonimoService.carrinhoAnonimoVerificado
          ) {
            this.showModalDivulgacao(res.data);
          }
          getCampaigns$.unsubscribe();
        });
    }

    if (this.fidelidadeService.cashbackParams()['ativo'] || (this.fidelidadeService.vorticeParams()['ativo'] && this.fidelidadeService.vorticeParams()['cashback'] == 1)) {
      this.buscarSaldoCashback();
    }
  }

  private buscaFidelidadeToken() {
    if (
      this.localStorageService.get('token') != null &&
      this.fidelidadeService.vorticeParams()['ativo']
    ) {
      this.fidelidadeService.findAndSaveToken();
    }
  }

  private showModalDivulgacao(campanhas) {
    const hasNew = this.fidelidadeService.hasNewCampaigns(campanhas);
    if (
      this.fidelidadeService.vorticeParams()['texto_campanha_fidelidade'] &&
      hasNew
    ) {
      let modal = this.modalService.show(DivulgacaoComponent, {
        keyboard: true,
        class: 'custom-modal w280',
        ignoreBackdropClick: false,
      });
      modal.content.campanhas = campanhas;
      modal.content.texto = this.fidelidadeService.vorticeParams()[
        'texto_campanha_fidelidade'
      ];
    }
  }

  public goBack() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }

  public showBack(): boolean {
    const currentRoute = this.location.path().split('?')[0];
    const rootRoutes = ['/', '', '/sorteios'];
    if (this.viewService.isInAppMode() && !rootRoutes.includes(currentRoute)) {
      return true;
    }
    return false;
  }

  private validaParametrosInApp() {
    if (this.viewService.isInAppMode()) {
      this.paginaService.index().then(() => {
        this.paginas = this.paginaService.paginas;
      });
    }
  }

  private setTagOfertaExclusiva() {
    this.tagOfertaExclusiva.descricao = this.filialService.detail.tag_oferta_exclusiva;
    this.tagOfertaExclusiva.slug = this.filialService.getSlugOfertaExclusiva();
  }

  private verificarAceiteTermosUso() {
    if (!this.televendas) {
      this.termosUsoService
        .getPossuiTermoUsoPendente(environment.filialId)
        .then(() => {
          if (this.termosUsoService.aprovacaoPendente) {
            const modalRef = this.modalService.show(TermosUsoComponent, {
              backdrop: 'static',
              keyboard: false,
            });
            modalRef.content.modalLevel = 2;
          }
        });
    }
  }

  private verificarDisponibilidadeTipoEntrega() {
    if (this.clienteLogado) {
      this.tipoEntregaService.getDisponibilidade(this.cdService.id);
    }
  }

  public carregarSaldoCashback() {
    this.saldoSubscription = this.cashbackService
      .saldo()
      .subscribe((cashBackSaldoResponse) => {
        if (cashBackSaldoResponse.success) {
          const {
            saldo: { saldo },
          } = cashBackSaldoResponse;
          this.saldo = saldo;
          this.mostrarSaldoCashback = true;
        }
      });
  }

  modalExtratoCashback() {
    const modalRef = this.modalService.show(
      MinhaContaCashbackModalExtratoComponent,
      {
        keyboard: true,
        class: 'modal-sm',
        ignoreBackdropClick: true,
      }
    );
  }

  private sincronizarCarrinhoAnonimoSubject() {
    this.carrinhoAnonimoService.escolhaCarrinhoCliente$
      .subscribe((manterExistente: boolean) => {
        if (manterExistente) {
          this.carrinhoService.manterCarrinhoExistente();
        } else {
          this.carrinhoService.sincronizarCarrinhoAnonimoCliente();
        }
      });

    this.carrinhoAnonimoService.sincronizarCarrinhoAnonimo$
      .subscribe((valor: string) => {
        if (valor !== this.carrinhoAnonimoService.sincronizar) {
          this.carrinhoService.escolherCarrinhoCliente();
        }
      });
  }
}
