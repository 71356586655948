// REFACTOR GLOBAL FUNCTIONS
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import pt from '@angular/common/locales/pt';
// REFACTOR GLOBAL FUNCTIONS
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from 'angular-2-local-storage';
import * as LogRocket from 'logrocket';
import { CookieService } from 'ngx-cookie-service';
/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from '../environments/environment';
import '../styles/headings.css';
import { AntifraudeService } from './antifraude/shared/antifraude.service';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState } from './app.service';
import { ClienteService } from './clientes/shared/cliente.service';
import { FilialService } from './filiais/shared/filial.service';
import { InstitucionalService } from './institucional/shared/institucional.service';
import { MinhaContaCashbackService } from './minha-conta/minha-conta-cashback/shared/minha-conta-cashback.service';
import { BannerService } from './partials/banners/shared/banner.service';
import { CarrinhoAnonimoService } from './partials/carrinho/shared/carrinho-anonimo.service';
import { CarrinhoService } from './partials/carrinho/shared/carrinho.service';
import { PaginacaoService } from './partials/paginacao/shared/paginacao.service';
import { TabsBarService } from './partials/tabs-bar/shared/tabs-bar.service';
import { CompraService } from './pedidos/shared/compra.service';
import { ProdutoBuscaService } from './produtos/produto-busca/shared/produto-busca.service';
import { ListaService } from './receitas/listas/shared/lista.service';
import { ReceitaService } from './receitas/shared/receita.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthCarrinhoAnonimoGuard } from './shared/guards/authCarrinhoAnonimo.guard';
import { AplicativoService } from './shared/services/aplicativo.service';
import { ColecaoService } from './shared/services/colecao.service';
import { CombosService } from './shared/services/combos.service';
import { CompartilharListaService } from './shared/services/compartilhar-lista.service';
import { ConfiguracaoService } from './shared/services/configuracao.service';
import { DepartamentoService } from './shared/services/departamento.service';
import { FidelidadeService } from './shared/services/fidelidade.service';
import { FooterService } from './shared/services/footer.service';
import { HttpClientInterceptor } from './shared/services/http.interceptor';
import { ImageService } from './shared/services/imagem.service';
import { MatchMediaService } from './shared/services/match-media.service';
import { MinhasListasService } from './shared/services/minhas-listas.service';
import { StartupService } from './shared/services/startup.service';
import { UsuarioService } from './televendas/shared/usuario.service';
import { TipoEntregaService } from './tipo-entregas/shared/tipo-entrega.service';
import { PinGuard } from './shared/guards/pin.guard';
export function serialize(query) {
    const qs = [];
    for (const key in query) {
        // skip loop if the property is from prototype
        if (!query.hasOwnProperty(key)) {
            continue;
        }
        const obj = query[key];
        if (obj instanceof Array) {
            for (const prop in obj) {
                qs.push(key + '[]=' + obj[prop]);
            }
        }
        else {
            qs.push(key + '=' + (obj == undefined ? null : obj));
        }
    }
    return qs.join('&');
}
if (environment.logRocket) {
    LogRocket.init('933jpi/store');
}
registerLocaleData(pt);
const ɵ0 = { storageType: 'localStorage' }, ɵ1 = { storageType: 'localStorage' }, ɵ2 = window;
// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    {
        provide: APP_INITIALIZER,
        useFactory: startupProviderFactory,
        deps: [StartupService],
        multi: true,
    },
    AppState,
    CarrinhoService,
    CompraService,
    ClienteService,
    TabsBarService,
    BannerService,
    InstitucionalService,
    DepartamentoService,
    ColecaoService,
    ReceitaService,
    ListaService,
    ProdutoBuscaService,
    FilialService,
    TipoEntregaService,
    Title,
    PaginacaoService,
    CombosService,
    CookieService,
    LocalStorageService,
    CompartilharListaService,
    MinhasListasService,
    ConfiguracaoService,
    UsuarioService,
    FidelidadeService,
    MatchMediaService,
    ImageService,
    AplicativoService,
    TipoEntregaService,
    AntifraudeService,
    AuthGuard,
    PinGuard,
    MinhaContaCashbackService,
    FooterService,
    AuthCarrinhoAnonimoGuard,
    CarrinhoAnonimoService,
    {
        provide: 'LOCAL_STORAGE_SERVICE_CONFIG',
        useValue: ɵ0,
    },
    {
        provide: 'LOCAL_STORAGE_SERVICE_CONFIG',
        useValue: ɵ1,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true },
    { provide: 'Window', useValue: ɵ2 },
    { provide: LOCALE_ID, useValue: 'pt' },
];
export function startupProviderFactory(provider) {
    return () => provider.load();
}
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
