import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { ClienteService } from '../../clientes/shared/cliente.service';
import { environment } from '../../../environments/environment';
import { ParametroService } from '../../parametros/shared/parametro.service';

@Injectable()
export class PinGuard implements CanActivate {

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
        private clienteService: ClienteService,
        private parametrosService: ParametroService
    ) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLoggedIn = !(this.localStorageService.get('token') == null);
        if (isLoggedIn) {
            if (environment.PIN_ATIVO_FEATURE_TOGGLE) {
                const { pin_ativo } = await this.parametrosService.buscarValidacaoPin();
                if (!pin_ativo) return true;
                try {
                    const { telefone_celular_validado } = await this.clienteService.consultaSolicitacaoSms();
                    if (telefone_celular_validado) {
                        return true;
                    }
                    this.router.navigate(['/clientes/verificar-celular'], { queryParams: { redirectUrl: state.url } });
                } catch (err) {
                    this.router.navigate(['/clientes/verificar-celular'], { queryParams: { redirectUrl: state.url } });
                }
            }
            return true;

        } else {
            this.router.navigate(['/clientes/login'], { queryParams: { redirectUrl: state.url } });
        }
    }
}
