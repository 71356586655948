import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { MinhaContaCashbackService } from '../shared/minha-conta-cashback.service';
import { FidelidadeService } from 'src/app/shared/services/fidelidade.service';
export class MinhaContaCashbackModalExtratoComponent {
    constructor(cashbackService, modalService, fidelidadeService) {
        this.cashbackService = cashbackService;
        this.modalService = modalService;
        this.fidelidadeService = fidelidadeService;
        this.extrato = [];
    }
    ngOnInit() {
        if (this.fidelidadeService.cashbackParams().ativo) {
            this.cashback = 'izio';
        }
        else if (this.fidelidadeService.vorticeParams().ativo && this.fidelidadeService.vorticeParams().cashback == 1) {
            this.cashback = 'idever';
        }
        this.carregarDadosCashback();
    }
    carregarDadosCashback() {
        Promise.all([this.carregarSaldo(), this.carregarExtrato()]);
    }
    carregarSaldo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.cashbackService.saldo().subscribe((cashbackSaldoResponse) => {
                if (cashbackSaldoResponse.success) {
                    const { saldo: { saldo }, } = cashbackSaldoResponse;
                    this.saldo = saldo;
                    this.loading = false;
                }
                else {
                    alert("Erro na comunicação com o cashback.");
                    this.fecharModal();
                }
            });
        });
    }
    carregarExtrato() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cashbackService.extrato().subscribe((cashbackExtratoResponse) => {
                if (cashbackExtratoResponse.success) {
                    this.loading = true;
                    const { extrato = [] } = cashbackExtratoResponse;
                    this.extrato = extrato.slice(0, 6);
                    this.loading = false;
                }
            });
        });
    }
    fecharModal() {
        this.modalService._hideModal(1);
    }
    getTipoOperacaoCredito(tipo) {
        return tipo % 2 === 1;
    }
}
