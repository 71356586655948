import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { ClienteService } from '../../clientes/shared/cliente.service';
import { environment } from '../../../environments/environment';
import { ParametroService } from '../../parametros/shared/parametro.service';
export class PinGuard {
    constructor(localStorageService, router, clienteService, parametrosService) {
        this.localStorageService = localStorageService;
        this.router = router;
        this.clienteService = clienteService;
        this.parametrosService = parametrosService;
    }
    canActivate(route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isLoggedIn = !(this.localStorageService.get('token') == null);
            if (isLoggedIn) {
                if (environment.PIN_ATIVO_FEATURE_TOGGLE) {
                    const { pin_ativo } = yield this.parametrosService.buscarValidacaoPin();
                    if (!pin_ativo)
                        return true;
                    try {
                        const { telefone_celular_validado } = yield this.clienteService.consultaSolicitacaoSms();
                        if (telefone_celular_validado) {
                            return true;
                        }
                        this.router.navigate(['/clientes/verificar-celular'], { queryParams: { redirectUrl: state.url } });
                    }
                    catch (err) {
                        this.router.navigate(['/clientes/verificar-celular'], { queryParams: { redirectUrl: state.url } });
                    }
                }
                return true;
            }
            else {
                this.router.navigate(['/clientes/login'], { queryParams: { redirectUrl: state.url } });
            }
        });
    }
}
