import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormatarRecuperacaoSenha } from 'src/app/shared/utils/formatar-recuperacao-senha';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "angular-2-local-storage";
import * as i2 from "@angular/common/http";
export class ClienteService {
    constructor(localStorageService, httpClient) {
        this.localStorageService = localStorageService;
        this.httpClient = httpClient;
        this.service = '/clientes';
        this.codigo_indicacao = '';
        this.informacoes = null;
        this.cadastro_incompleto = false;
        this.cliente$ = null;
        this.informacoes_cashback = null;
        this.preferences = {};
        this.changePreferences$ = new Subject();
        this.afterLogin$ = new Subject();
        this.afterLogout$ = new Subject();
        this.autorizadoUsarCookie = null;
        this.STORAGE_NAME_LGPD_COOKIE = 'lgpd-aceite-cookie';
        this._cliente = {};
        const segundos = parseFloat(this.localStorageService.get('date'));
        const lastLogin = new Date();
        lastLogin.setTime(segundos);
        function monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth() + 1;
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
        if (monthDiff(lastLogin, new Date()) >= 1) {
            this.localStorageService.remove('token');
            this.localStorageService.remove('cliente');
            this.localStorageService.remove('pedido');
            window.dispatchEvent(new Event('atualizarCliente'));
            setTimeout(() => {
                window.dispatchEvent(new Event('atualizarCarrinhoCliente'));
            }, 100);
        }
        this.nome = this.localStorageService.get('nome');
        this.setAutorizadoUsarCookie();
    }
    get cliente() {
        return this._cliente;
    }
    saveCliente(cliente) {
        this._cliente = cliente;
    }
    login(data) {
        const URI = `filial/${environment.filialId}/logar`;
        return this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, data)
            .toPromise()
            .then((resp) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.validarAutenticacao(resp);
        }));
    }
    autenticar(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.savarDataParaComparacaoDoUltimoLogin();
            this.salvarToken(token);
            this.getInformacaoGeralCliente();
            yield this.getDescricaoCliente();
            this.afterLogin$.next();
        });
    }
    validarAutenticacao(resp) {
        if (!resp.success) {
            alert('Login inválido. Verifique o usuário e a senha e tente novamente.');
            return false;
        }
        const token = resp.data;
        this.savarDataParaComparacaoDoUltimoLogin();
        this.salvarToken(token);
        this.getInformacaoGeralCliente();
        return this.getDescricaoCliente().then(() => {
            this.afterLogin$.next();
            return true;
        });
    }
    salvarToken(token) {
        this.localStorageService.set('token', token);
    }
    savarDataParaComparacaoDoUltimoLogin() {
        this.localStorageService.set('date', new Date().getTime());
    }
    setNomeCliente(nome) {
        this.nome = nome;
    }
    salvarNomeCliente(nome) {
        this.localStorageService.set('nome', nome);
    }
    logout() {
        return this.httpClient
            .post(this.service + '/logout', [])
            .toPromise()
            .then(() => {
            this.cliente$ = null;
            this._resetInformacoesCarrinho();
        });
    }
    logged() {
        return this.localStorageService.get('token') !== null;
    }
    eTeleVendas() {
        return !!this.localStorageService.get('operador');
    }
    add(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.httpClient
                .post(`${environment.apiUrl}/loja/clientes/add/${environment.filialId}`, data)
                .toPromise();
        });
    }
    edit(data) {
        return this.httpClient.post(this.service + '/edit/' + data.id, data);
    }
    alterarSenha(data) {
        return this.httpClient.post(this.service + '/alterar-senha/' + data.id, data);
    }
    alterarLogin(data) {
        return this.httpClient.post(this.service + '/alterar-login/' + data.id, data);
    }
    recuperarSenha(data) {
        return this.httpClient.post(this.service + '/recuperar-senha/' + environment.filialId, (data = FormatarRecuperacaoSenha.data(data)));
    }
    redefinirSenha(chave) {
        return this.httpClient.get(this.service + '/redefinir-senha/' + chave);
    }
    redefinirSenhaConfirm(chave, data) {
        return this.httpClient.post(this.service + '/redefinir-senha/' + chave, data);
    }
    confirmarCodigo(codigo) {
        return this.httpClient.post(this.service + '/confirmar-codigo/' + environment.filialId + '/' + codigo, { codigo });
    }
    getPedidoEmProcesso() {
        return this.httpClient.get(this.service + '/pedido-em-processo/' + environment.filialId);
    }
    setPedidoEmProcesso(pedido) {
        this.localStorageService.set('pedido', pedido);
    }
    confirmarToken(token) {
        return this.httpClient.post(this.service + '/confirmar-token/' + environment.filialId + '/' + token, { token });
    }
    _resetInformacoesCarrinho() {
        if (!!this.localStorageService.get('operador')) {
            this.localStorageService.remove('token');
            this.localStorageService.remove('cliente');
        }
        else {
            this.localStorageService.clearAll('^((?!(loja|auth|lgpd)).)*$');
        }
        this.afterLogout$.next();
        window.dispatchEvent(new Event('atualizarCarrinho'));
    }
    recebimentoNewsletter(email) {
        return this.httpClient.post(this.service + '/recebimento-newsletters/' + environment.filialId, { email });
    }
    editPreference(preferences) {
        if (this.cliente) {
            this.localStorageService.set('preferences', preferences);
            this.changePreferences$.next(preferences);
        }
    }
    getPreferences() {
        this.preferences = this.localStorageService.get('preferences') || {};
        return this.preferences;
    }
    permitirCadastro(cep) {
        const URL = `loja/clientes/cadastro/filial/${environment.filialId}/cep/${cep}/permitir`;
        return this.httpClient.get(`${environment.apiUrl}/${URL}`);
    }
    getInformacoes() {
        if (this.informacoes != null && this.informacoes.success) {
            return Promise.resolve(this.informacoes);
        }
        return this.httpClient
            .get(`${environment.apiUrl}/loja${this.service}/informacoes`)
            .toPromise()
            .then((response) => {
            this.informacoes = response.data;
            return response.data;
        });
    }
    getInformacaoGeralCliente() {
        if (!this.cliente$) {
            this.httpClient
                .get(`${environment.apiUrl}/loja/cliente/index`)
                .subscribe((res) => {
                this.saveCliente(res.data);
                this.cliente$ = of(res.data);
            });
        }
    }
    getDescricaoCliente() {
        return this.httpClient
            .get(`${environment.apiUrl}/loja/cliente/descricao`)
            .toPromise()
            .then((resp) => {
            const nome = resp.data.descricao;
            this.setNomeCliente(nome);
            this.salvarNomeCliente(nome);
        });
    }
    getInformacoesClienteCompletarCadastro() {
        return this.httpClient
            .get(`${environment.apiUrl}/loja/cliente/index`)
            .pipe(map((response) => response.data))
            .toPromise();
    }
    getClienteEnderecoCompletarCadastro() {
        return this.httpClient.get(`${environment.apiUrl}/loja/clientes/enderecos`)
            .pipe(map((response) => response.data[0]))
            .toPromise();
    }
    aceitarUsoCookie() {
        if (this.logged()) {
            this.httpClient
                .put(`${environment.apiUrl}/loja/clientes/aceite_cookies`, {})
                .toPromise();
        }
    }
    salvarAutorizacaoUsoCookie(autorizado = true) {
        this.localStorageService.set(this.STORAGE_NAME_LGPD_COOKIE, autorizado);
    }
    buscarAceiteUsoCookie() {
        return this.httpClient.get(`${environment.apiUrl}/loja/clientes/aceite_cookies`);
    }
    setAutorizadoUsarCookie() {
        this.autorizadoUsarCookie =
            this.localStorageService.get(this.STORAGE_NAME_LGPD_COOKIE) || false;
    }
    getAutorizadoUsarCookieLocal() {
        return (this.autorizadoUsarCookie ||
            this.localStorageService.get(this.STORAGE_NAME_LGPD_COOKIE) ||
            false);
    }
    buscarSessaoCliente() {
        if (!this.getSessaoCliente()) {
            return this.httpClient.get(`${environment.apiUrl}/loja/sessao_cliente`)
                .toPromise()
                .then((res) => {
                this.setSessaoCliente(res.data.sessao_id);
            });
        }
    }
    setSessaoCliente(sessao) {
        this.localStorageService.set('sessao', sessao);
    }
    getSessaoCliente() {
        return this.localStorageService.get('sessao');
    }
    getCartoesSalvos(cdId, tipoEntregaId) {
        return this.httpClient
            .get(`${environment.wsUrl}/clientes/cartoes/filial/${environment.filialId}/centro_distribuicao/${cdId}/tipo_entrega/${tipoEntregaId}`)
            .pipe(map((res) => res.cartoes));
    }
    excluirCartao(id) {
        return this.httpClient.delete(`${environment.wsUrl}/clientes/cartoes/${id}`);
    }
    solicitarSms(telefoneCelular) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const URI = `clientes/filial/${environment.filialId}/solicitar_sms_pin`;
            const response = yield this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, {
                telefone_celular: telefoneCelular
            })
                .toPromise();
            return response;
        });
    }
    consultaSolicitacaoSms() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const URI = 'clientes/otp';
            const response = yield this.httpClient.get(`${environment.apiUrl}/loja/${URI}`)
                .toPromise();
            return response.data;
        });
    }
    validarOtp(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const URI = 'clientes/otp/validar';
            const response = yield this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, {
                token: code
            })
                .toPromise();
            return response;
        });
    }
    invalidarUsuarioOtp(telefone_celular) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const URI = 'clientes/otp/invalidar';
            const response = yield this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, {
                telefone_celular
            }).toPromise();
            return response;
        });
    }
}
ClienteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClienteService_Factory() { return new ClienteService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.HttpClient)); }, token: ClienteService, providedIn: "root" });
